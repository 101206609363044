* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: 0;
}

html {
  /* a cada 1rem será considerado 10px */
  font-size: 62.5%;
}

html,
body {
  height: 100vh;
}

body {
  font-size: 1.6rem;
}

h1{
  font-weight: 700;
  font-size: 1.8em;
}

.label{
  color: #282828;
  font-weight: 700;
}
.title{
  font-weight: 700;
  font-size: 1.8em;
}

.input, label{
  font-size: 1.5rem;
}

.button{
  font-size: 1.5rem;
}

.button-white{
  background: rgb(247, 247, 247);
  background: linear-gradient(
        94deg,
        rgba(247, 247, 247, 1) 39%,
        rgba(255, 255, 255, 1) 100%
  );
    border: none;
    padding: 10px;
    font-weight: 600;
    font-size: 0.9em;
    letter-spacing: 0.3px;
    -webkit-box-shadow: 9px 7px 9px -5px rgb(97 96 96 / 32%);
    box-shadow: 9px 7px 9px -5px rgb(97 96 96 / 32%);
    color: #9339a2;
}

.button-white:hover{
  background: rgba(255, 255, 255, 0.7);
}

.button-purple{
  background: rgb(131, 43, 146);
  background: linear-gradient(
        267deg,
        rgba(131, 43, 146, 1) 39%,
        rgba(160, 69, 176, 1) 100%
  );
    border: none;
    color: white;
    font-weight: 600;
    letter-spacing: 0.2px;
}

.button-purple:hover{
  background: #c986d8;
  color: white;
}

.button-yellow{
  color: white;
  border: none;
  font-weight: 600;
  letter-spacing: 0.3px;
  background: rgb(194,144,3);
  background: linear-gradient(266deg, rgba(194,144,3,1) 35%, rgba(227,175,31,1) 100%);
}

.button-yellow:hover{
  background: rgba(194, 144, 3, 0.7);
}

.Flexfrete{
  height: 100vh;
  position: relative;
}

.App {
  text-align: center;
  background-color: #f2f2f2;
  background-image:url('bg.png');
  background-attachment: fixed;
  background-size:auto;
  background-position:center;
  position: relative;
  min-height: 100vh;
}

.FlexFrete-content{
  padding-top: 45px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.page-container-style{
  width: 100vw;
  padding-bottom: 2.5rem; 
  min-height: 70vh;
}

.private{
  padding-left: 0;
}

.private-bg-footer-color{
  background-color: #e6e6e6;
}


@media (max-width: 2560px) {
  .column-doc-gap{
    padding-right: 3rem;
    padding-bottom: 3rem;
  }
}

@media (max-height: 1024px) {

}

@media (max-width: 770px) {

  body{
    overflow-x: hidden;
  }
  .column-doc-gap{
    padding: .90rem;
  }
  .is-clipped{
    position: fixed;
  }

  .input, label{
    font-size: 1.3rem;
  }
}

@media (max-width: 700px) {
  body{
    overflow-x: hidden;
  }

  .containerFooterLogado {
    padding-top: 20em!important;
  }
  .input, label{
    font-size: 1.3rem;
  }
}

@media (max-width: 375px) {
  html {
    font-size: 50%;
  }

  .input, label{
    font-size: 1.3rem;
  }
}

@media (max-width: 360px) {
  html {
    font-size: 50%;
  }
}

.page-title{
  font-weight: bold;
  font-size: 2rem;
  margin-bottom:  40px;
}
.label-grey{
  color: #9a9a9a;
}
.link-background-none{
  border: 1px solid #808080 !important;
  color: #808080 !important;
  background: none !important;
}

.landing-page-section{
  max-width: 100%;
  background: white!important;
}

.labelInputDecoration{
  display: flex;
  align-items: baseline;
  position: relative;
}

.span-decoration{
  position: absolute;
  top: 1em;
  left: 10em;
  
}

@media screen and (min-width: 1600px) {
  .span-decoration{
      left: 13em
  }
}

@media screen and (max-width: 700px) {
  .span-decoration{
      left: 20em
  }
}